// General
import format from 'date-fns/format';
import { Form, Formik, FormikHelpers } from 'formik';
import { graphql, navigate } from 'gatsby';
// Hooks
import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import Button from 'ui-kit/button/button';
import PageSectionHeader from 'ui-kit/page-section-header/page-section-header';
import PasswordText from 'ui-kit/password-text/password-text';
// Ui-kit
import PhoneNumberText from 'ui-kit/phone-number-text/phone-number-text';
import Text from 'ui-kit/text/text';

// Display components
import ManageGroupCard from 'display-components/membership/manage-group-card-list';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import PageLayout from 'components/layouts/page/page.layout';
// Components
import PasswordRules from 'components/password-rules/password-rules.component';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
// States
import {
    joinMembershipPlanInviteListSelector,
    joinMembershipPlanUserBearerTokenSelector
} from 'state/join-membership-plan/join-membership-plan.selectors';
import {
    membershipRegistrationRegisterRoutine,
    membershipRegistrationValidateInviteRoutine
} from 'state/membership-registration/membership-registration.routines';
import {
    membershipRegistrationIsValidatingInviteSelector,
    membershipRegistrationValidateInviteSelector
} from 'state/membership-registration/membership-registration.selectors';
import { joinMembershipInvitationPlanStatusMap } from 'state/membership/membership.helpers';

import { SETUP_MEMBERSHIP_ACCOUNT } from 'schema/membership/setup-account.schema';

// Interfaces
import { ManageGroupCardProps } from 'types/membership';

import { getPhoneNumber } from 'util/globalVariables';
// Utils
import { lowercaseAndCapitalize } from 'util/string';

// HOC
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';

// Styles
import './index.style.scss';

// Interface for Join Membership Plan Form
interface JoinMembershipPlanRegisterForm {
    phoneNumber: string;
    email: string;
    password?: string;
    passwordConfirm?: string;
}

/**
 * Helper function to translate invite from Membership to be used inside the ManageGroup Card.
 * @param accountStatus number
 * @returns MyCaregiversCardProps['accountStatus']
 */
const getManageGroupCardInvitationStatus = (invitationStatus: number): ManageGroupCardProps['invitationStatus'] => {
    const invitationStatusLabel = joinMembershipInvitationPlanStatusMap.get(invitationStatus);

    switch (invitationStatusLabel) {
        case 'active':
            return 'accepted';
        case 'partialAccess':
            return 'accepted';
        default:
            return invitationStatusLabel as ManageGroupCardProps['invitationStatus'];
    }
};

// Main component
const SetupMembershipAccount = ({ data }: { data: GatsbyTypes.SetupMembershipAccountDataQuery }) => {
    // General
    const { backgroundImage } = data;
    const modalErrorText = 'modals.joinMembershipPlanRegisterFailure';

    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [membershipOwnerName, setMembershipOwnerName] = useState<string>('');

    //Selectors
    const joinMembershipPlanBearerToken = useSelector(joinMembershipPlanUserBearerTokenSelector);
    const inviteList = useSelector(joinMembershipPlanInviteListSelector);
    const isValidatingInvite = useSelector(membershipRegistrationIsValidatingInviteSelector);
    const validateInviteData = useSelector(membershipRegistrationValidateInviteSelector);

    useEffect(() => {
        if (!joinMembershipPlanBearerToken) {
            navigate('/link-expired?flow=join-membership-plan');
        }
    }, [joinMembershipPlanBearerToken]);

    useEffect(() => {
        setIsLoading(isValidatingInvite);
    }, [isValidatingInvite]);

    useEffect(() => {
        if (inviteList.length > 0) {
            const {
                recipientFirstName,
                recipientLastName,
                recipientDateOfBirth,
                recipientEmailAddress,
                senderFirstName,
                senderLastName
            } = inviteList[0];

            setMembershipOwnerName(lowercaseAndCapitalize(`${senderFirstName} ${senderLastName}`));

            if (isValidatingInvite) {
                dispatch(
                    membershipRegistrationValidateInviteRoutine.trigger({
                        validateInviteData: {
                            firstName: recipientFirstName,
                            lastName: recipientLastName,
                            birthDate: format(new Date(recipientDateOfBirth), 'MM-dd-yyyy'),
                            email: recipientEmailAddress
                        },
                        onFailure: () => navigate('/no-invitations-available')
                    })
                );
            }
        }
    }, [dispatch, inviteList, isValidatingInvite]);

    const parsePwError = (errors: Record<string, any>, values: Record<string, any>) => {
        const { password, passwordConfirm } = values;

        if (errors?.password || errors?.passwordConfirm) {
            if (password !== passwordConfirm) {
                return t(`pages.profile.membership.setupMembership.contactAndSignUpForm.errors.passwordConfirm`);
            }

            if (errors?.passwordConfirm === 'minLength') {
                return t(`registration.validationRules.minLength`);
            }

            const errorMessage = errors?.password?.split(',');
            return t(`registration.validationRules.${errorMessage ? errorMessage[0] : 'charactersInARow'}`);
        }

        return undefined;
    };

    const parsePhoneError = (errors: Record<string, any>, translation: TFunction<'translation'>) => {
        const phoneErrorMessages: Record<string, any> = {
            phoneNumber: translation('components.addFamilyMember.personalDetails.fieldErrors.phoneNumber'),
            phoneNumberInvalid: translation(
                'pages.profile.membership.setupMembership.contactAndSignUpForm.errors.phoneNumber'
            )
        };

        return errors?.phoneNumber ? phoneErrorMessages[errors.phoneNumber] : undefined;
    };

    const handleOnSubmit = async (
        values: JoinMembershipPlanRegisterForm,
        helpers: FormikHelpers<JoinMembershipPlanRegisterForm>
    ) => {
        setIsBusy(true);

        const data = {
            ...validateInviteData,
            AutoRefill: false,
            Username: values.email,
            Email: values.email,
            PhoneNumber: values.phoneNumber,
            Password: values.password,
            PasswordConfirm: values.passwordConfirm
        };

        dispatch(
            membershipRegistrationRegisterRoutine.trigger({
                formValues: data,
                formHelpers: helpers,
                onFailure: () => {
                    setIsBusy(false);
                    handleShowErrorModal();
                },
                onSuccess: () => {
                    setIsBusy(false);
                    navigate('/confirm-email?flow=birdi-care&type=join-membership-plan');
                }
            })
        );
    };

    const handleShowErrorModal = useCallback(() => {
        dispatch(
            openModal({
                showClose: false,
                size: 'lg',
                type: 'danger',
                headerContent: <BirdiModalHeaderDanger headerText={t(`${modalErrorText}.title`)} icon="alert" />,
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t(`${modalErrorText}.body`)}
                        note={`${t(`${modalErrorText}.footNoteText`, {
                            phoneNumber: getPhoneNumber({ isEnd: true })
                        })}`}
                    />
                ),
                ctas: [
                    {
                        label: t(`${modalErrorText}.buttonText`),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'JoinMembershipPlanRegister'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    return (
        <div className="setup-account-page">
            <PageLayout
                headerImage={backgroundImage}
                headerImageClassName="profile-background"
                pageContentClassName="profile-wrapper"
                suppressApplicationPage={true}
            >
                <div className="profile setup-membership-account">
                    <Container fluid="xl">
                        <Row>
                            <Col xs={12} md={12} lg={8}>
                                <PageSectionHeader
                                    eyebrowText={t('pages.profile.membership.setupMembership.eyebrowText')}
                                    title={t('pages.profile.membership.setupMembership.sectionTitle')}
                                />
                                {isLoading ? (
                                    <div className="header-paragraphs">
                                        <p>{t('components.spinner.loading')}</p>
                                    </div>
                                ) : (
                                    <>
                                        <div className="header-paragraphs">
                                            <div className="header-paragraphs-only-mobile">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: sanitizeHtml(
                                                            t(
                                                                'pages.profile.membership.setupMembership.initialGreeting'
                                                            )
                                                        )
                                                    }}
                                                />
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: sanitizeHtml(
                                                            t(
                                                                'pages.profile.membership.setupMembership.initialGreetingDescription',
                                                                {
                                                                    membershipOwner: membershipOwnerName
                                                                }
                                                            ),
                                                            {
                                                                allowedTags: ['strong']
                                                            }
                                                        )
                                                    }}
                                                />
                                            </div>

                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeHtml(
                                                        t(
                                                            'pages.profile.membership.setupMembership.setupAccountInitialGreeting',
                                                            {
                                                                membershipOwner: membershipOwnerName
                                                            }
                                                        ),
                                                        {
                                                            allowedTags: ['strong']
                                                        }
                                                    )
                                                }}
                                                className="only-desktop"
                                            />
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeHtml(
                                                        t('pages.profile.membership.setupMembership.additionalInfo', {
                                                            membershipOwner: membershipOwnerName
                                                        }),
                                                        {
                                                            allowedTags: ['strong']
                                                        }
                                                    )
                                                }}
                                            />
                                        </div>

                                        {inviteList &&
                                            inviteList.length > 0 &&
                                            inviteList.map((invite) => (
                                                <ManageGroupCard
                                                    key={`join-membership-plan-card-id-${invite.invitationId}`}
                                                    memberName={`${invite.recipientFirstName} ${invite.recipientLastName}`}
                                                    accessGrantedDate={format(
                                                        new Date(invite.dateGranted),
                                                        'MM/dd/yyyy'
                                                    )}
                                                    invitationStatus={getManageGroupCardInvitationStatus(invite.status)}
                                                />
                                            ))}

                                        <div className="setup-membership-account__member-contact-sign-in">
                                            <Formik
                                                onSubmit={handleOnSubmit}
                                                validationSchema={SETUP_MEMBERSHIP_ACCOUNT}
                                                initialValues={{
                                                    password: undefined,
                                                    passwordConfirm: undefined,
                                                    email: inviteList[0].recipientEmailAddress,
                                                    phoneNumber: inviteList[0].recipientMobilePhone
                                                }}
                                                validateOnChange={false}
                                                validateOnBlur={false}
                                            >
                                                {({
                                                    values,
                                                    touched,
                                                    errors,
                                                    handleBlur,
                                                    setFieldValue,
                                                    setFieldTouched,
                                                    validateField
                                                }) => {
                                                    const handlePasswordChange = async (e: React.ChangeEvent<any>) => {
                                                        await setFieldValue('password', e.target.value);
                                                        validateField('password');
                                                        validateField('passwordConfirm');
                                                    };

                                                    const handleConfirmPasswordChange = async (
                                                        e: React.ChangeEvent<any>
                                                    ) => {
                                                        await setFieldValue('passwordConfirm', e.target.value);
                                                        validateField('password');
                                                        validateField('passwordConfirm');
                                                    };

                                                    const handlePhoneChange = async (e: React.ChangeEvent<any>) => {
                                                        await setFieldValue('phoneNumber', e.target.value);
                                                        validateField('phoneNumber');
                                                    };

                                                    const handleEmailChange = async (e: React.ChangeEvent<any>) => {
                                                        await setFieldValue('email', e.target.value);
                                                        validateField('email');
                                                    };

                                                    return (
                                                        <Form>
                                                            <div className="form-header">
                                                                <p className="form-instructions">
                                                                    {t(
                                                                        'pages.profile.membership.setupMembership.contactAndSignUpForm.title'
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <p className="form-info">
                                                                {t(
                                                                    'pages.profile.membership.setupMembership.contactAndSignUpForm.providedByOwner'
                                                                )}
                                                            </p>
                                                            <PhoneNumberText
                                                                name="phoneNumber"
                                                                label={t('forms.membership.personalDetails.mobile')}
                                                                onChange={handlePhoneChange}
                                                                touched={!!errors?.phoneNumber || touched.phoneNumber}
                                                                value={values?.phoneNumber}
                                                                defaultValue={inviteList[0].recipientMobilePhone}
                                                                errors={parsePhoneError(errors, t)}
                                                                countryCode={t(`countryCode`)}
                                                                onBlur={(e) => {
                                                                    setFieldTouched('phoneNumber', true);
                                                                    handleBlur(e);
                                                                }}
                                                            />
                                                            <p className="form-info-hints">
                                                                {t(
                                                                    'pages.profile.membership.setupMembership.contactAndSignUpForm.phoneInfo'
                                                                )}
                                                            </p>
                                                            <Text
                                                                name="email"
                                                                label={t('forms.membership.personalDetails.email')}
                                                                onChange={handleEmailChange}
                                                                touched={!!errors?.email || touched.email}
                                                                value={values?.email}
                                                                defaultValue={inviteList[0].recipientEmailAddress}
                                                                errors={
                                                                    errors?.email
                                                                        ? t(
                                                                              'pages.profile.membership.setupMembership.contactAndSignUpForm.errors.email'
                                                                          )
                                                                        : undefined
                                                                }
                                                                onBlur={(e) => {
                                                                    setFieldTouched('email', true);
                                                                    handleBlur(e);
                                                                }}
                                                            />
                                                            <p className="form-info-hints">
                                                                {t(
                                                                    'pages.profile.membership.setupMembership.contactAndSignUpForm.emailInfo'
                                                                )}
                                                            </p>
                                                            <PasswordText
                                                                name="password"
                                                                label={t('registration.password')}
                                                                type="password"
                                                                onChange={handlePasswordChange}
                                                                onBlur={handleBlur}
                                                                touched={touched.password}
                                                                value={values?.password}
                                                                errors={errors?.password}
                                                            />
                                                            <PasswordRules
                                                                errors={errors?.password}
                                                                touched={touched.password}
                                                                password={values?.password}
                                                            />
                                                            <PasswordText
                                                                name="passwordConfirm"
                                                                label={t('registration.passwordConfirm')}
                                                                type="password"
                                                                onChange={handleConfirmPasswordChange}
                                                                onBlur={handleBlur}
                                                                errors={parsePwError(errors, values)}
                                                                touched={
                                                                    !!errors?.passwordConfirm ||
                                                                    touched.passwordConfirm ||
                                                                    touched.password
                                                                }
                                                                value={values?.passwordConfirm}
                                                                showErrorsText={true}
                                                                autoComplete="new-password"
                                                            />
                                                            <div className="form-ctas">
                                                                <Button
                                                                    async
                                                                    className="create-account-button"
                                                                    isBusy={isBusy}
                                                                    disabled={isBusy}
                                                                    label={t(
                                                                        'pages.profile.membership.setupMembership.contactAndSignUpForm.createAccountCta'
                                                                    )}
                                                                    variant="primary"
                                                                    type="submit"
                                                                />
                                                            </div>
                                                        </Form>
                                                    );
                                                }}
                                            </Formik>
                                        </div>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </PageLayout>
        </div>
    );
};

export default withUnauthenticatedSessionExpiration(SetupMembershipAccount, 'join-membership-plan');

export const query = graphql`
    query SetupMembershipAccountData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
